import React,{useEffect,useState} from 'react'
import axios from "axios"
import { useParams } from 'react-router-dom'; 


const Pay = () => {
  const amount = 500;
  const currency = "INR";
  const receiptId = "qwsaq1";
  const paymentHandler = async (e) => {
    const response = await fetch("https://xmu3d5amtc.execute-api.ap-south-1.amazonaws.com/dev/createOrders", {
      method: "POST",
      body: JSON.stringify({
        amount,
        currency,
        receipt: receiptId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const order = await response.json();


    var options = {
      key: "rzp_live_aoZjw4YHKJ6xsc",
      amount,
      currency,
      name: "Akshat Corp",
      description: "Test Transaction",
      image: "https://www.placehunt.in/images/logo.png",
      order_id: order.id,
      handler: async function (response) {
        console.log(response);
        const body = {
          ...response,
        };

        const validateRes = await fetch(
          "https://xmu3d5amtc.execute-api.ap-south-1.amazonaws.com/dev/validateOrders",
          {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const jsonRes = await validateRes.json();
        console.log(jsonRes);
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: "Akshat", //your customer's name
        email: "akshat@example.com",
        contact: "7409994059", //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#ff4949",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    e.preventDefault();
  };

  const { token } = useParams(); 
  const [data, setData] = useState(null)

  useEffect(()=>{
    const tempToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwaG9uZU51bWJlciI6Ijc0MDk5OTQwNTkiLCJmaXJzdE5hbWUiOiJha3NoYXQiLCJsYXN0TmFtZSI6ImJhaHVndW5hIiwidXNlclR5cGUiOiJjdXN0b21lciIsInVzZXJJZCI6ImRkNTljZTY3LTBkOTAtNDA5NC05YTZkLWQ4NDc2OGEwNjY2MiIsImlhdCI6MTcwMjA0MzA4MX0.Fcr8N6i6-mnFGWN5DccbKAbIgRFP_62qmCvt67cZs08';
    console.log(tempToken)
    axios.get('https://xmu3d5amtc.execute-api.ap-south-1.amazonaws.com/dev/verifyJwt',{headers: {
      Authorization: `Bearer ${token}`
    }}).then(res => {  
            setData(res?.data?.tokenDetails)
          })
          .catch(error => {
              console.error(error);
          });   
  },[token])


  return (
    <div style={{margin:20}}>
      <button onClick={paymentHandler}>Pay Now</button>
      <h6>{token}</h6>
      <h1>{data?.firstName}</h1>
      <h1>{data?.lastName}</h1>
      <h1>{data?.userType}</h1>
      <h1>{data?.userId}</h1>
      <h1>{data?.phoneNumber}</h1>
    </div>
  )
}

export default Pay
