import React from "react";
import { footer } from "../../data/Data";
import "./footer.css";
import { Link } from "react-router-dom";
import PlayStoreImage from "../../../assets/images/playstore.png";
import AppStoreImage from "../../../assets/images/appstorebadge.png";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="box">
            <div className="logo">
              <h2>Do You Need Help With Anything?</h2>
              <p>App Available on App store And Play Store</p>
              <div className="app-store-play-store-icons">
                <a
                  href="https://apps.apple.com/in/app/placehunt/id6475617316"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={AppStoreImage}
                    alt="App Store"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.placehuntnewapp&pcampaignid=web_share"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={PlayStoreImage}
                    alt="Play Store"
                  />
                </a>
              </div>
            </div>
          </div>

          {
            footer.map((val) => (
              <div className="box" key={val.title}>
                <h3>{val.title}</h3>
                <ul>
                  {val.text.map((items, itemIndex) => (
                    <li key={itemIndex}>
                      <Link to={items.path} className="footer-link">
                        {items.list}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
        </div>
      </footer>
      <div className="copyright">
        <span className="right-text">
          <i className="fa fa-phone"></i> +91-7579241494 | placehunt.in@gmail.com
        </span>
        <span className="right-text">© 2024 Placehunt. All rights reserved.</span>
      </div>
    </>
  );
};

export default Footer;
