import React,{useEffect,useState} from 'react'
import axios from "axios"
import { useParams,useHistory } from 'react-router-dom'; 
import styles from './VerifyLocation.module.css';
const VerifyLocation = () => {
  const { token,locationId } = useParams(); 
  const [data, setData] = useState(null)
  const [noDataError, setNoDataError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  
  const currency = "INR";
  const history = useHistory();
  const paymentHandler = async (e) => {
    setIsLoading(true)
    const locationId = data?.locationId; // Replace with actual location ID
  const userId = data?.userId; // Replace with actual user ID
    const response = await fetch("https://xmu3d5amtc.execute-api.ap-south-1.amazonaws.com/dev/createOrders", {
      method: "POST",
      body: JSON.stringify({
        locationId,
        userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const order = await response.json();
    if(response.status !== 200){
      setIsLoading(false)
      alert("Error! Please Try Again Later")
      return
    }

    var options = {
      key: "rzp_live_SgJVeXLv5cmOvV",
      amount:order?.amount*100,
      currency,
      name: "Placehunt",
      description: `Payment for Placehunt Property Type - ${data?.propertyType.toUpperCase()}`,
      image: "https://www.placehunt.in/images/logo.png",
      order_id: order.orderId,
      handler: async function (response) {
        setIsLoading(true)
        const body = {
          ...response,
        };
        const validateRes = await fetch(
          "https://xmu3d5amtc.execute-api.ap-south-1.amazonaws.com/dev/validateOrders",
          {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const jsonRes = await validateRes.json();

        if(jsonRes.msg === "success"){
          setIsLoading(false)
          history.push('/payment-status/success');
        } else{
          setIsLoading(false)
          history.push('/payment-status/failed');

        }
      },
      prefill: {
        name: `${data?.firstName}," ",${data?.lastName}`,
        // email: "akshat@example.com",
        contact: data?.phoneNumber,
      },
      notes: {
        // address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#ff4949",
      },
    };
    var rzp1 = new window.Razorpay(options);
    setIsLoading(false)
    rzp1.on("payment.failed", function (response) {
      setIsLoading(false)
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    // history.push('/payment-status/success');
    e.preventDefault();
  };




  useEffect(() => {
    axios.post('https://xmu3d5amtc.execute-api.ap-south-1.amazonaws.com/dev/getUserUnpublishedLocationByLocationId', 
      { locationId }, 
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(res => { 
     
      setData(res?.data?.locationDetails)
    })
    .catch(error => {
    if(error.response.status === 403){
      // alert("Payment Already Done, Please refresh the Placehunt Application")
      setNoDataError("Payment Already Done, Please refresh the Placehunt Application")
    } else{
      setNoDataError("Unexpected Error")
    }

    });   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openGoogleMaps = (latitude, longitude) => {
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    let url;

    if (iOS) {
        // Attempt to open in Google Maps if installed, otherwise open in Apple Maps
        url = `comgooglemaps://?q=${latitude},${longitude}&zoom=18`;
        if (!window.open(url)) { // If the attempt fails, open Apple Maps
            url = `http://maps.apple.com/?q=${latitude},${longitude}&z=18`;
            window.open(url);
        }
    } else {
        url = `https://www.google.com/maps?q=${latitude},${longitude}&hl=en&z=18&t=k`;
        window.open(url, '_blank');
    }
};



  return (
    <div className={styles.container}>

    {data ? (
        <div className={styles.userDetails}>
              <h1 className={styles.heading}>Verify Location Details</h1>
          <p><strong>Property Name:</strong> {data.title}</p>
          <p><strong>Owner Name:</strong> {`${data.ownerName}`}</p>
          <p><strong>Landmark:</strong> {data?.address?.landmark}</p>
          <p><strong>Locality:</strong> {data?.address?.locality}</p>
          <p><strong>City:</strong> {data?.address?.city}</p>
          <p><strong>State:</strong> {data?.address?.state}</p>
          <p><strong>Country:</strong> {data?.address?.country}</p>
          <p><strong>Pincode:</strong> {data?.address?.pincode}</p>
          <p><strong>Phone Number:</strong> {data.phoneNumber}</p>
          {data?.latitude && data?.longitude? <p>
                    <strong>Coordinates:</strong> {data?.latitude.toString().slice(0, 10)}, {data?.longitude.toString().slice(0, 10)}
                        <span 
                            className={styles.locationIcon}
                            onClick={() => isLoading?{}:openGoogleMaps(data.latitude, data.longitude)}
                        >
                            📍
                        </span>
                    </p>:null}
          <p><strong>Property Type:</strong> {data.propertyType.toUpperCase()}</p>
          <p><strong>Validity:</strong> 1 Year</p>
          <p><strong>Price:</strong> {data.propertyType === "hostel"?"₹10000" : "₹5000"}</p>
          <button className={styles.submitButton} disabled={isLoading} onClick={isLoading?null:paymentHandler}>{isLoading?"Please Wait Loading...":"Submit"}</button>
                </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight:"60vh", textAlign:"center" }}>
       {noDataError? noDataError :"Loading..."}
      </div>
      )}
    </div>
  )
}

export default VerifyLocation
