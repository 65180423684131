import React from "react"
import { featured } from "../../data/Data"

const FeaturedCard = () => {
  return (
    <>
      <div className='content grid4 mtop'>
        {featured.map((items, index) => (
          <div className='box' key={index}>
            <img src={items.cover} alt='' />
            <h2>{items.name}</h2>
            {/* <label>{items.total}</label> */}
          </div>
        ))}
      </div>
    </>
  )
}

export default FeaturedCard
