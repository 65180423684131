import React from "react"
import Header from "../common/header/Header"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Home from "../home/Home"
import Footer from "../common/footer/Footer"
import About from "../about/About"
import Contact from "../contact/Contact"
import Pay from "../pay/Pay"
// import Listings from "../listings/Listings"
import Privacy from "../privacyPolicy/Privacy"
import TermsConditions from "../termsandconditions/TermsConditions"
import ScrollToTop from "../common/ScrollToTop"
import VerifyLocation from "../home/verifyLocation/VerifyLocation"
import PaymentStatus from "../home/paymentStatus/PaymentStatus"
import ApproveRejectLocation from "../home/approveRejectLocation/ApproveRejectLocation"
import ListProperty from "../listProperty/ListProperty"
import LoginSignup from "../loginSignup/LoginSignup"
import UserUnpaidListings from "../userUnpaidListings/UserUnpaidListings"
import { Helmet } from "react-helmet"

const Pages = () => {
  return (
    <>
      <Router>
      <ScrollToTop />
        <Header />
        <Helmet>
        <title>{`Placehunt - PG, Hostel, Flat - Upes Dehradun`}</title>
        <meta name="description" content="Find the best PG, Flat, or Hostel near UPES Dehradun effortlessly. Explore curated listings for a seamless accommodation search. Your ideal living space awaits!" />
        <meta name="keywords" content="placehunt hostel, placehunt boys hostel, placehunt girls hostel, boys hostel, girls hostel, boys hostel near upes, girls hostel near upes, flat near upes dehradun, pg near upes dehradun, upes, dehradun" />


        <script type="application/ld+json">{JSON.stringify({
          "@context": "https://schema.org",
          "@type": "HostelUpesDehradun",
          "name": "PG, Flat, Hostel Near Upes Dehradun",
          "description": "Find the best PG, Flat, or Hostel near UPES Dehradun effortlessly. Explore curated listings for a seamless accommodation search. Your ideal living space awaits!",
          "image": "https://www.placehunt.in/static/media/logo.f8ce6f22c91f8359aa16.png",
          "keywords": "UPES, FLAT, PG,Hostel, UPES, Dehradun"
        })}</script>
      </Helmet>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/about' component={About} />
          {/* <Route exact path='/listings' component={Listings} /> */}
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/privacy-policy' component={Privacy} />
          <Route exact path='/terms-and-conditions' component={TermsConditions} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/pay/:token' component={Pay} />
          <Route exact path='/list-property' component={ListProperty} />
          <Route exact path='/login' component={LoginSignup} />
          <Route exact path='/verify-location/:token/:locationId' component={VerifyLocation} />
          <Route exact path='/payment-status/:status' component={PaymentStatus} />
          <Route exact path='/payment-pending' component={UserUnpaidListings} />
          <Route exact path='/all-paid-but-unapproved-locations-to-verify/:token' component={ApproveRejectLocation} />
        </Switch>
        <Footer />
      </Router>
    </>
  )
}

export default Pages
