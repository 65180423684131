import React from "react";
import "./hero.css";
import HomeImage from "../../../assets/images/home.jpg";
import iOSDownloadImage from "../../../assets/images/appstorebadge.jpg";
import androidDownloadImage from "../../../assets/images/playstore.jpg";

const Hero = () => {
  return (
    <section className='hero'>
      <div className='container'>
        <div className='content'>
          <h1>Find PG, Hostel, Rooms Near UPES Dehradun</h1>
          <h2>Explore a wide variety of accommodations in Kandoli, Bidholi, Pondha</h2>
          <p>Download Placehunt app now to find the perfect accommodation.</p>
          <div className='download-buttons'>
            <a  href="https://apps.apple.com/in/app/placehunt/id6475617316" target="_blank" rel="noreferrer" aria-label="Download iOS App">
              <img className='ios-download-image' src={iOSDownloadImage} alt="Download iOS App" />
            </a>
            <a  href="https://play.google.com/store/apps/details?id=com.placehuntnewapp&pcampaignid=web_share" rel="noreferrer" target="_blank" aria-label="Download Android App">
              <img className='android-download-image' src={androidDownloadImage} alt="Download Android App" />
            </a>
          </div>
        </div>
        <div className='image'>
          <img src={HomeImage} alt="Placehunt app" loading="lazy" />
        </div>
      </div>
    </section>
  );
}

export default Hero;
