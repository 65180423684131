import React from "react";
import Heading from "../../common/Heading";
import { location } from "../../data/Data";
import "./style.css";

const Location = () => {
  const updatedSubtitle = "Discover Comfortable and Affordable PG Accommodations";
  return (
    <>
      <section className='location padding'>
        <div className='container'>
          <Heading title='Explore Flat, PG, Room, Hostel in Bidholi and Kandoli' subtitle={updatedSubtitle} />

          <div className='content locationGrid mtop'>
            {location.map((item, index) => (
              <div className='box' key={index}>
                <img src={item.cover} alt='' />
                <div className='centered-text'>
                  <h2>{item.name}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Location;

