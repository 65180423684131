export const nav = [
  {
    text: "home",
    path: "/",
  },
  {
    text: "about",
    path: "/about",
  },
  // {
  //   text: "services",
  //   path: "/services",
  // },
  // {
  //   text: "Listings",
  //   path: "/listings",
  // },
  // {
  //   text: "pricing",
  //   path: "/pricing",
  // },
  {
    text: "contact",
    path: "/contact",
  },
  {
    text: "list Property",
    path: "/list-property",
  },
]
export const featured = [
  {
    cover: "../images/hero/home-icon.png",
    name: "Hostels",
    url: "",
  },
  {
    cover: "../images/hero/pg.png",
    name: "PG",
    url: "",
  },
  {
    cover: "../images/hero/flat.png",
    name: "Flat",
    url: "",
  },
  {
    cover: "../images/hero/room.png",
    name: "Shared Rooms",
    url: "",
  },
  // {
  //   cover: "../images/hero/h6.png",
  //   name: "Villa & Condo",
  //   url: "",
  // },
]
export const list = [
  {
    id: 1,
    cover: "../images/list/p1.jpg",
    name: "Nest Hostels UPES Dehradun",
    location: "Near UPES, Dehradun",
    category: "For Rent",
    price: "₹7000",
    type: "Hostel",
  },
  // {
  //   id: 2,
  //   cover: "../images/list/p2.jpg",
  //   name: "Mine Apartment",
  //   location: "5698 Zirak Road, NewYork",
  //   category: "For Rent",
  //   price: "₹9000",
  //   type: "Flat",
  // },
  // {
  //   id: 3,
  //   cover: "../images/list/p3.jpg",
  //   name: "Sangam Homes",
  //   location: "Kandoli Premnagar, Dehradun",
  //   category: "For Rent",
  //   price: "₹6500",
  //   type: "PG",
  // }
]
export const awards = [
  {
    icon: <i className='fa-solid fa-trophy'></i>,
    num: "32 M	",
    name: "Blue Burmin Award",
  },
  {
    icon: <i className='fa-solid fa-briefcase'></i>,
    num: "43 M",
    name: "Mimo X11 Award",
  },
  {
    icon: <i className='fa-solid fa-lightbulb'></i>,
    num: "51 M",
    name: "Australian UGC Award",
  },
  {
    icon: <i className='fa-solid fa-heart'></i>,
    num: "42 M",
    name: "IITCA Green Award",
  },
]

export const location = [
  {
    id: 1,
    name: "Hostels, PG, Flat, Room In Kandoli",
    cover: "./images/location/upes-kandoli.jpg",
  },
  {
    id: 2,
    name: "Hostels, PG, Flat, Room In Bidholi",
    cover: "./images/location/upes-bidholi2.jpg",
  }
  // {
  //   id: 2,
  //   name: "Graphic Era University, Dehradun",
  //   cover: "./images/location/graphicera.webp",
  // },
]
export const team = [
  {
    list: "50",
    cover: "../images/customer/team-1.jpg",
    address: "Liverpool, Canada",
    name: "Sargam S. Singh",
    icon: [<i className='fa-brands fa-facebook-f'></i>, <i className='fa-brands fa-linkedin'></i>, <i className='fa-brands fa-twitter'></i>, <i className='fa-brands fa-instagram'></i>],
  },
  {
    list: "70",
    cover: "../images/customer/team-2.jpg",
    address: "Montreal, Canada",
    name: "Harijeet M. Siller",
    icon: [<i className='fa-brands fa-facebook-f'></i>, <i className='fa-brands fa-linkedin'></i>, <i className='fa-brands fa-twitter'></i>, <i className='fa-brands fa-instagram'></i>],
  },
  {
    list: "80",
    cover: "../images/customer/team-3.jpg",
    address: "Denever, USA",
    name: "Anna K. Young",
    icon: [<i className='fa-brands fa-facebook-f'></i>, <i className='fa-brands fa-linkedin'></i>, <i className='fa-brands fa-twitter'></i>, <i className='fa-brands fa-instagram'></i>],
  },
  {
    list: "51",
    cover: "../images/customer/team-4.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i className='fa-brands fa-facebook-f'></i>, <i className='fa-brands fa-linkedin'></i>, <i className='fa-brands fa-twitter'></i>, <i className='fa-brands fa-instagram'></i>],
  },
  {
    list: "42",
    cover: "../images/customer/team-5.jpg",
    address: "2272 Briarwood Drive",
    name: "Michael P. Grimaldo",
    icon: [<i className='fa-brands fa-facebook-f'></i>, <i className='fa-brands fa-linkedin'></i>, <i className='fa-brands fa-twitter'></i>, <i className='fa-brands fa-instagram'></i>],
  },
  {
    list: "38",
    cover: "../images/customer/team-5.jpg",
    address: "Montreal, USA",
    name: "Adam K. Jollio",
    icon: [<i className='fa-brands fa-facebook-f'></i>, <i className='fa-brands fa-linkedin'></i>, <i className='fa-brands fa-twitter'></i>, <i className='fa-brands fa-instagram'></i>],
  },
]
export const price = [
  {
    id:1,
    plan: "Basic",
    price: "100",
    ptext: "per month",
    list: [
      {
        id:1,
        icon: <i className='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        id:2,
        icon: <i className='fa-solid fa-check'></i>,
        text: "1 Property Listing",
      },
      {
        id:3,
        icon: <i className='fa-solid fa-check'></i>,
        text: "360 Degree Room View",
      },
      { change: "color", icon: <i className='fa-solid fa-x'></i>, text: "Personal Help Support" },
      { change: "color", icon: <i className='fa-solid fa-x'></i>, text: "Enterprise SLA" },
    ],
  },
  {
    id:2,
    best: "Best Value",
    plan: "Standard",
    price: "250",
    ptext: " per month",
    list: [
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "99.5% Uptime Guarantee",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "3 Property Listing",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "360 Degree Room View",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        // change: "color",
        icon: <i className='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
  {
    id:3,
    plan: "Platinum",
    price: "500",
    ptext: "per month",
    list: [
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "100% Uptime Guarantee",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "10 Property Listing",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "360 Degree Room View",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Personal Help Support",
      },
      {
        icon: <i className='fa-solid fa-check'></i>,
        text: "Enterprise SLA",
      },
    ],
  },
]
export const footer = [
  {
    title: "LAYOUTS",
    text: [{ list: "Home",path:"/" }, { list: "About Page",path:"/about" }, { list: "Contact Page",path:"/contact" },{ list: "Privacy Policy",path:"/privacy-policy" },{ list: "Terms And Conditions",path:"/terms-and-conditions" }],
  },
  // {
  //   title: "ALL SECTIONS",
  //   text: [{ list: "Headers" }, { list: "Features" }, { list: "Attractive" }, { list: "Testimonials" }, { list: "Videos" }, { list: "Footers" }],
  // },
  // {
  //   title: "COMPANY",
  //   text: [{ list: "About" }, { list: "Blog" }, { list: "Pricing" }, { list: "Affiliate" }, { list: "Login" }, { list: "Changelog" }],
  // },
]
