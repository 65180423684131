import React from 'react';
import { useParams } from 'react-router-dom';
import successImage from '../../../assets/images/success.png'
import failedImage from '../../../assets/images/failed.png';    // Adjust the path as needed

const PaymentStatus = () => {
    const { status } = useParams();
  
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
        {status === 'success' ? (
          <>
            <img src={successImage} alt="Success" style={{ maxWidth: '90vw', maxHeight: '300px', width: 'auto', height: 'auto', marginBottom: '20px' }} />
            <h3>Thank You, Request Successful</h3>
            <h4 style={{ textAlign:"center"}}>We are verifying the details</h4>
            <h4 style={{textAlign:"center"}}>We will update it soon.</h4>
          </>
        ) : (
          <>
            <img src={failedImage} alt="Failed" style={{ maxWidth: '90vw', maxHeight: '300px', width: 'auto', height: 'auto', marginBottom: '20px' }} />
            <h1>Payment Failed</h1>
          </>
        )}
      </div>
    );
  }
  
  export default PaymentStatus;
  