import React from "react";
import img from "../../assets/images/contactus.jpg";
import contactImg from "../../assets/images/contact.png";
import Back from "../common/Back";
import "./contact.css";

const Contact = () => {
  return (
    <>
      <section className='contact mb'>
        <Back name='Contact Us' title='Contact Us' cover={img} />
        <div className='container contact-container'>
          <div className='contact-form'>
            <form className='shadow' onSubmit={(event)=>{ event.preventDefault()}}>
              <h4 className="contact-title">Fillup The Form</h4>
              <div>
                <input className="contact-text" type='text' placeholder='Name' />
                <input className="contact-text" type='text' placeholder='Email' />
              </div>
              <input className="contact-text" type='text' placeholder='Subject' />
              <textarea cols='30' rows='10'></textarea>
              <button className="submitButton">Submit Request</button>
            </form>
          </div>

          <div className='contact-image'>
            <img src={contactImg} alt='Contact Us' />
          </div>
        </div>
       
      </section>
      <div className='container contact-info mb'>
            <h4 className="contact-info-title">Contact Us</h4>
            <p><strong>Address:</strong> Upper Kandoli, Via Premnagar, Dehradun, Uttarakhand, India, 248007</p>
            <p><strong>Phone:</strong> +91 7579241494</p>
            <p><strong>Email:</strong> placehunt.in@gmail.com</p>
          </div>
    </>
  );
};

export default Contact;
