import React from "react"
import Back from "../common/Back"
import Heading from "../common/Heading"
import img from "../../assets/images/about.jpg"
import aboutImg from "../../assets/images/aboutus.png"
import "./about.css"

const About = () => {
  return (
    <>
      <section className='about'>
        <Back name='About Us' title='Our Story & Vision' cover={img} />
        <div className='container flex mtop'>
          <div className='left row'>
            <Heading title='Placehunt Chronicles: Crafting Your Home Away from Home'  />

            <p>At Placehunt.in, we're more than just a property rental website; we're a gateway to your next adventure in education and life. Our journey began with a simple yet powerful vision: to connect students and young professionals with living spaces that are not just places to stay, but homes that foster growth, learning, and unforgettable memories.</p>
            <p>Rooted in the heart of vibrant university towns, our curated list of PGs, hostels, and flats are more than mere addresses; they're launching pads for dreams. We understand that every student's needs are unique - from quiet corners for the midnight oil burners to lively communal areas for the social butterflies. That's why we've dedicated ourselves to providing a diverse range of living options that cater to every individual's lifestyle and budget.</p>
            <p>Our story is woven with countless tales of students finding their ideal home away from home. We're not just about beds, desks, and roofs; we're about building communities. Whether you're a first-year student stepping into a new chapter of life or a seasoned scholar in pursuit of higher education, our mission is to ensure that your transition is seamless, comfortable, and filled with positive experiences.</p>
            <p>We invite you to explore Placehunt.in and discover not just a place to live, but a space where your academic and personal journey can flourish. Let's connect and take the first step towards finding your ideal student accommodation."</p>


            {/* <button className='btn2'>Lets Connect</button> */}
          </div>
          <div className='right row'>
            <img src={aboutImg} alt='' />
          </div>
        </div>
      </section>
    </>
  )
}

export default About
