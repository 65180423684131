import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import { useHistory } from "react-router-dom";
const LoginSignup = () => {
    const history = useHistory();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [otp, setOtp] = useState("");
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loginData, setLoginData] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");


    useEffect(() => {
        const storedToken = localStorage.getItem('userToken');
        if (storedToken) {
            history.push('/list-property');
        }
    }, [history]);

    const handleSendOtp = async () => {
        setIsLoading(true);
        const phoneNumberRegex = /^\+91\d{10}$/;
        if (!phoneNumberRegex.test(phoneNumber)) {
            setLoginData({ error: "Invalid phone number format" });
            setIsLoading(false);
            return;
        }
        axios
            .post(`https://xmu3d5amtc.execute-api.ap-south-1.amazonaws.com/dev/loginSignup`, {
                phoneNumber: phoneNumber,
            })
            .then((res) => {
                if (res?.data?.phoneNumber) {
                    setLoginData(res?.data);
                    setIsLoading(false);
                    setIsOtpSent(true);
                } else {
                    setLoginData({ error: "Unable To send OTP" });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setLoginData({ error: error });
                setIsLoading(false);
            });
    };

    const handleVerifyOtp = async () => {
        setIsLoading(true);
        let bodyParam = {}
        if (!otp) {
            setIsLoading(false);
            alert("Otp is required")
            return;
        }
        if (loginData?.isUserVerified) {
            bodyParam = { phoneNumber: phoneNumber, otp: otp }
        } else {
            if (!otp || !firstName) {
                setIsLoading(false);
                alert("First Name is required for verification.")
                return;
            }
            bodyParam = { phoneNumber, otp, firstName, lastname:lastName }
        }

        axios.post(`https://xmu3d5amtc.execute-api.ap-south-1.amazonaws.com/dev/verifyOtp`, bodyParam).then((res) => {
            console.log(res.data)
            if (res?.data?.message === "success") {
                setLoginData(res?.data)
                localStorage.setItem('userToken', res?.data?.token);
                setIsLoading(false);
                window.location.reload();
                window.location.href = '/list-property';
                // history.push('/list-property');
            } else {
                console.log(res.data)
                alert("Invalid Otp")
                setIsLoading(false);
            }

        }).catch((error) => {
            console.log(error)
            alert("Invalid Otp")
            setIsLoading(false);
        })
    };

    return (
        <div
            style={{
                maxWidth: "400px",
                margin: "50px auto",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                textAlign: "center",
            }}
        >
            <h2 style={{ textAlign: "center" }}>Login</h2>
            <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                    <label style={{ fontWeight: "bold", marginBottom: "5px", textAlign: "left" }}>
                        Phone Number:
                    </label>
                    <PhoneInput
                        international
                        defaultCountry="IN"
                        placeholder="+91"
                        value={phoneNumber}
                        onChange={(value) => setPhoneNumber(value)}
                        style={{
                            width: "100%",
                            border: "1px solid #ccc",
                            padding: "8px",
                            boxSizing: "border-box",
                        }}
                        disabled={isOtpSent}
                        countries={["IN"]}
                    />
                </div>
                {!isOtpSent ? (
                    <button
                        style={{ width: "100%", position: "relative" }}
                        onClick={handleSendOtp}
                        disabled={isLoading}
                    >
                        {isLoading && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            >
                                Loading...
                            </div>
                        )}
                        {!isLoading && "Send OTP"}
                    </button>
                ) : null}
                {isOtpSent && !loginData?.isUserVerified && (
                    <>
                        <input
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            style={{
                                padding: "8px",
                                marginTop: "10px",
                                marginBottom: "10px",
                                width: "100%",
                                boxSizing: "border-box",
                                border: "1px solid #ccc",
                            }}
                            placeholder="Enter First Name"
                        />
                        <input
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            style={{
                                padding: "8px",
                                marginTop: "10px",
                                marginBottom: "10px",
                                width: "100%",
                                boxSizing: "border-box",
                                border: "1px solid #ccc",
                            }}
                            placeholder="Enter Last Name (Optional)"
                        />
                    </>
                )}
                {isOtpSent && (
                    <input
                        type="text"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        style={{
                            padding: "8px",
                            marginTop: "10px",
                            marginBottom: "10px",
                            width: "100%",
                            boxSizing: "border-box",
                            border: "1px solid #ccc",
                        }}
                        placeholder="Enter OTP"
                    />
                )}
                {isOtpSent && (
                    <button
                        style={{ width: "100%", position: "relative" }}
                        onClick={handleVerifyOtp}
                        disabled={isLoading}
                    >
                        {isLoading && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                }}
                            >
                                Loading...
                            </div>
                        )}
                        {!isLoading && "Verify OTP"}
                    </button>
                )}
            </div>
        </div>
    );
};

export default LoginSignup;
