import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const UserUnpaidListings = () => {
    const history = useHistory();
    const userToken = localStorage.getItem("userToken");
    const [userListings, setUserListings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getUserListings = () => {
        setIsLoading(true);
        const config = {
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        };
        axios.get(`https://xmu3d5amtc.execute-api.ap-south-1.amazonaws.com/dev/getUserLocationsByUserId`, config)
            .then((res) => {
                if (res?.data?.items) {
                    // Filter out items where isPaid is false
                    const unpaidListings = res?.data?.items.filter(item => !item.isPaid);
                    setUserListings(unpaidListings);
                    setIsLoading(false);
                } else {
                    setUserListings([]);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setUserListings([]);
                setIsLoading(false);
            });
    }

    const handlePayNow = (token, locationId) => {
        history.replace(`/verify-location/${token}/${locationId}`);
    }

    useEffect(() => {
        if (userToken) {
            getUserListings();
        }
        // eslint-disable-next-line
    }, [userToken]);

    return (
        <div style={pageContainerStyle}>
            <h1 style={headingStyle}>Pending Locations</h1>
            {isLoading ? <div>Loading...</div> : userListings.length>0?
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={tableHeaderStyle}>Title</th>
                            <th style={tableHeaderStyle}>Owner Name</th>
                            <th style={tableHeaderStyle}>Property Type</th>
                            <th style={tableHeaderStyle}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userListings.map((listing) => (
                            <tr key={listing.locationId} style={tableRowStyle}>
                                <td style={tableCellStyle}>{listing.title}</td>
                                <td style={tableCellStyle}>{listing.ownerName}</td>
                                <td style={tableCellStyle}>{listing.propertyType}</td>
                                <td style={tableCellStyle}>
                                    <button style={payNowButtonStyle} onClick={() => handlePayNow(userToken, listing.locationId)}>
                                        Pay Now
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>:<div>No Pending Locations exist.</div>
            }
        </div>
    );
}

const pageContainerStyle = {
    textAlign: 'center',
    minHeight:"70vh"
};

const headingStyle = {
    fontSize: '24px',
    margin: '20px 0',
};

const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
};

const tableHeaderStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
};

const tableRowStyle = {
    border: '1px solid #ddd',
};

const tableCellStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
};

const payNowButtonStyle = {
    padding: '8px',
    cursor: 'pointer',
};

export default UserUnpaidListings;
