import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
const CustomRadioButton = ({ label, checked, onChange }) => (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <input type="radio" checked={checked} onChange={onChange} />
    <span style={{ marginLeft: '8px', color: 'black' }}>{label}</span>
  </div>
);

const Card = ({ children }) => (
  <div style={{
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    maxWidth: '600px',
    margin: '20px auto',
  }}>
    {children}
  </div>
);



const LocalityDropdown = ({ value, onChange, options }) => (
  <select style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }} value={value} onChange={(e) => onChange(e.target.value)}>
    {options.map((option, index) => (
      <option key={index} value={option.value}>
        {option.text}
      </option>
    ))}
  </select>
);

const ListProperty = () => {

  const history = useHistory();
  useEffect(() => {
    const storedToken = localStorage.getItem('userToken');
    if (!storedToken) {
        history.push('/login');
    }
}, [history]);

const handleLogout = () => {
  // Remove userToken from localStorage
  localStorage.removeItem('userToken');

  // Refresh the page
  window.location.reload();
};
  const [ownerName, setOwnerName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [propertyType, setPropertyType] = useState('hostel');
  const [landmark, setLandmark] = useState('');
  const [locality, setLocality] = useState('Kandoli');
  const [city, setCity] = useState('Dehradun');
  const [state, setState] = useState('Uttarakhand');
  const [country, setCountry] = useState('India');
  const [pincode, setPincode] = useState('248007');
  const [referralId, setReferralId] = useState('');
  const [isLoading, setIsLoading] = useState(false);



  const consolidateAddress = () => {
    return {
      locality: locality,
      landmark: landmark,
      city: city,
      state: state,
      country: country,
      pincode: pincode,
    };
  };
  const localityData = [
    { text: 'Kandoli', value: 'Kandoli' },
    { text: 'Bidholi', value: 'Bidholi' },
    { text: 'Pondha', value: 'Pondha' },
    { text: 'Doonga', value: 'Doonga' },
    { text: 'Nanda ki Chowki', value: 'Nanda ki Chowki' },
  ];

  const addLocation = async () => {
    setIsLoading(true)
    // Validate ownerName, contactNumber, propertyName, and landmark
    if (!ownerName.trim() || !contactNumber.trim() || !propertyName.trim() || !landmark.trim()) {
      alert('Details Missing, Please fill all the details');
      setIsLoading(false)
      return;
    }
  
    // Validate contactNumber to be a 10-digit number
    const contactNumberRegex = /^[0-9]{10}$/;
    if (!contactNumber.match(contactNumberRegex)) {
      alert('Invalid Contact Number. Please enter a 10-digit number');
      setIsLoading(false)
      return;
    }
  
    // Validate pincode to be a 6-digit number
    const pincodeRegex = /^[0-9]{6}$/;
    if (!pincode.match(pincodeRegex)) {
      alert('Invalid Pincode. Please enter a 6-digit number');
      setIsLoading(false)
      return;
    }
  
    const data = {
      ownerName,
      contactNumber,
      propertyName,
      propertyType,
      address: consolidateAddress(),
      agentReferralId: referralId || null,
      // latitude:"30.389743117413044",
      // longitude:"77.968958504498"
    };
  
    try {
      const userToken = localStorage.getItem('userToken')
      const response = await axios.post('https://xmu3d5amtc.execute-api.ap-south-1.amazonaws.com/dev/addLocation', data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if(response?.data?.locationId){
        history.replace(`/verify-location/${userToken}/${response?.data?.locationId}`);
        setIsLoading(false)
      }
      setIsLoading(false)
    } catch (error) {
      console.error('Error while adding location', error);
      alert('Error while adding location');
      setIsLoading(false)
    }
    setIsLoading(false)
  };
  

  return (
    <>
    
    <Card>
    <div style={{ position: 'relative' }}>
        {/* Logout button at the top right within the Card */}
        <button
          style={{
            position: 'absolute',
            top: '0px',
            right: '10px',
            padding: '10px',
            backgroundColor: '#ff4949',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
      <h1 style={{ textAlign: 'center', marginTop: '40px' }}>List Your Property</h1>

      <label style={{ display: 'block', marginBottom: '10px' }}>Owner Name:</label>
      <input type="text" style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }} value={ownerName} onChange={(e) => setOwnerName(e.target.value)} />

      <label style={{ display: 'block', marginBottom: '10px' }}>Contact Number (Without country code):</label>
      <input type="text" style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }} value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />

      <label style={{ display: 'block', marginBottom: '10px' }}>Hostel/PG Name:</label>
      <input type="text" style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }} value={propertyName} onChange={(e) => setPropertyName(e.target.value)} />

      <label style={{ display: 'block', marginBottom: '10px' }}>Landmark:</label>
      <input type="text" style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }} value={landmark} onChange={(e) => setLandmark(e.target.value)} />

      <label style={{ display: 'block', marginBottom: '10px' }}>Locality:</label>
      <LocalityDropdown value={locality} onChange={(value) => setLocality(value)} options={localityData} />

      <label style={{ display: 'block', marginBottom: '10px' }}>City:</label>
      <input type="text" style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }} disabled={true} value={city} onChange={(e) => setCity(e.target.value)} />

      <label style={{ display: 'block', marginBottom: '10px' }}>State:</label>
      <input type="text" style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }} disabled={true} value={state} onChange={(e) => setState(e.target.value)} />

      <label style={{ display: 'block', marginBottom: '10px' }}>Country:</label>
      <input type="text" style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }} disabled={true} value={country} onChange={(e) => setCountry(e.target.value)} />

      <label style={{ display: 'block', marginBottom: '10px' }}>Pincode:</label>
      <input type="text" style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }} disabled={true} value={pincode} onChange={(e) => setPincode(e.target.value)} />

      <label style={{ display: 'block', marginBottom: '10px' }}>Referral Id:</label>
      <input type="text" style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }} value={referralId} onChange={(e) => setReferralId(e.target.value)} />

      <label style={{ display: 'block', fontSize: '14px', fontWeight: 'bold', color: 'grey', marginBottom: '4px', marginLeft: '2px', marginTop: '10px' }}>Property Type:</label>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <CustomRadioButton label="Hostel" checked={propertyType === 'hostel'} onChange={() => setPropertyType('hostel')} />
        <CustomRadioButton label="PG" checked={propertyType === 'pg'} onChange={() => setPropertyType('pg')} />
        <CustomRadioButton label="Flat" checked={propertyType === 'flat'} onChange={() => setPropertyType('flat')} />
      </div>

      <button style={{ marginTop: '20px', width: '100%', padding: '10px', backgroundColor: '#ff4949', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }} onClick={addLocation} disabled={isLoading}>
        {isLoading?"Loading...":"Submit"}
      </button>

      
    </Card>
    </>
  );
};

export default ListProperty;
