import React, { useState, useEffect } from "react";
import "./header.css";
import { nav } from "../../data/Data";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";

const Header = () => {
  // const scrollToBottom = () => {
  //   window.scrollTo({
  //     top: document.documentElement.scrollHeight,
  //     behavior: "smooth",
  //   });
  // };
  const [navList, setNavList] = useState(false);
  const [userToken, setUserToken] = useState(null);

  useEffect(() => {
    // Retrieve userToken from localStorage
    const storedToken = localStorage.getItem("userToken");
    setUserToken(storedToken);
  }, []); // Removed localStorage from the dependency array

  const closeDropdown = () => {
    setNavList(false);
  };
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = Logo;
  }, []);


  return (
    <>
      <header>
        <div className='container flex'>
          <div className='logo'>
            <a href="/" onClick={closeDropdown}>
            {imageLoaded && <img src={Logo} alt='' />}
            </a>
          </div>
          <div className='nav'>
            <ul className={navList ? "small" : "flex"}>
              {nav.map((list, index) => (
                <li key={index}>
                  <Link to={list.path} onClick={closeDropdown}>
                    {list.text}
                  </Link>
                </li>
              ))}
              {userToken && (
                <>
                  <li>
                    <Link to="/payment-pending" onClick={closeDropdown}>
                      Pending Payments
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
          {/* <div className='button flex'>
            <button className='btn1' onClick={scrollToBottom}>
              <i className='fa fa-download'></i> Download App
            </button>
          </div> */}

          <div className='toggle'>
            <button onClick={() => setNavList(!navList)}>
              {navList ? <i className='fa fa-times'></i> : <i className='fa fa-bars'></i>}
            </button>
          </div>
        </div>
      </header>

  
    </>
  );
};

export default Header;
