import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function ApproveRejectLocation() {
    const { token } = useParams();
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const openGoogleMaps = (latitude, longitude) => {
        const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

        let url;

        if (iOS) {
            // Attempt to open in Google Maps if installed, otherwise open in Apple Maps
            url = `comgooglemaps://?q=${latitude},${longitude}&zoom=18`;
            if (!window.open(url)) { // If the attempt fails, open Apple Maps
                url = `http://maps.apple.com/?q=${latitude},${longitude}&z=18`;
                window.open(url);
            }
        } else {
            url = `https://www.google.com/maps?q=${latitude},${longitude}&hl=en&z=18&t=k`;
            window.open(url, '_blank');
        }
    };

    const fetchLocations = async () => {
        try {
            const response = await axios.get('https://xmu3d5amtc.execute-api.ap-south-1.amazonaws.com/dev/getAllPaidUnpublishedLocations', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setLocations(response?.data?.paidLocations);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };
    useEffect(() => {
      
        fetchLocations();
        // eslint-disable-next-line
    }, [token]);

    const handleTitleClick = (location) => {
        setSelectedLocation(location);
        setIsModalOpen(true);
    };

    const handleAcceptReject = async (approve,locationId,userId) => {
        setIsLoading(true)
        try{
            await axios.post('https://xmu3d5amtc.execute-api.ap-south-1.amazonaws.com/dev/approveRejectLocation', {locationId, userId,approve }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

        } catch(errror){
            alert(errror)
        }
        
        fetchLocations()
        setIsModalOpen(false)
        setIsLoading(false)

    }


    const Modal = () => {
        if (!selectedLocation) return null;
        const data = selectedLocation;

        return (
            <div style={modalStyle}>
                <div style={modalHeaderStyle}>
                    <button style={closeButtonStyle} onClick={() => setIsModalOpen(false)}>Close</button>
                </div>
                <div style={modalContentStyle}>
                    <h1 style={headingStyle}>Verify Location Details</h1>
                    <p><strong>Property Name:</strong> {data.title}</p>
                    <p><strong>Owner Name:</strong> {data.ownerName}</p>
                    <p><strong>Landmark:</strong> {data?.address?.landmark}</p>
                    <p><strong>Locality:</strong> {data?.address?.locality}</p>
                    <p><strong>City:</strong> {data?.address?.city}</p>
                    <p><strong>State:</strong> {data?.address?.state}</p>
                    <p><strong>Country:</strong> {data?.address?.country}</p>
                    <p><strong>Pincode:</strong> {data?.address?.pincode}</p>
                    <p><strong>Phone Number:</strong> {data.phoneNumber}</p>
                    <p>
                        <strong>Direction:</strong>
                        <span
                            className={{
                                color: "blue",
                                cursor: "pointer",
                                marginLeft: "8px",
                                fontSize: "20px",
                            }}
                            onClick={() => isLoading ? {} : openGoogleMaps(data.latitude, data.longitude)}
                        >
                            📍
                        </span>
                    </p>
                    <p><strong>Property Type:</strong> {data.propertyType.toUpperCase()}</p>
                </div>
                <div style={modalFooterStyle}>
                    <div style={buttonGroupStyle}>
                        <button style={actionButtonStyle} onClick={() => handleAcceptReject(true, data.locationId, data.userId)}>Accept</button>
                        <button style={actionButtonStyle} onClick={() => handleAcceptReject(false, data.locationId, data.userId)}>Reject</button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div style={{ minHeight: "100vh" }}>
            {isModalOpen && <Modal />}
            {locations.length > 0 ? (
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={cellStyle}>Title</th>
                            <th style={cellStyle}>Owner Name</th>
                            <th style={cellStyle}>Property Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {locations.map((location, index) => (
                            <tr key={index}>
                                <td style={{ ...cellStyle, cursor: 'pointer' }} onClick={() => handleTitleClick(location)}>
                                    {location.title}
                                </td>
                                <td style={cellStyle}>{location.ownerName}</td>
                                <td style={cellStyle}>{location.propertyType}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No locations found</p>
            )}
        </div>
    );
}

// Styles
const tableStyle = {
    width: '100%',
    textAlign: 'center',
    borderCollapse: 'collapse',
    border: '1px solid black'
};

const cellStyle = {
    border: '1px solid black',
    padding: '8px'
};

const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    zIndex: 1000,
    border: '2px solid black',
    borderRadius: '5px',
    maxWidth: '500px',
    width: '90%'
};

const modalHeaderStyle = {
    display: 'flex',
    justifyContent: 'flex-end'
};

const modalContentStyle = {
    margin: '10px 0'
};

const modalFooterStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
};


const closeButtonStyle = {
    cursor: 'pointer',
    background: 'none',
    fontSize: '16px',
    padding: '5px',
    color: "black",
    border: "1px solid black"
};

const actionButtonStyle = {
    margin: '5px',
    padding: '5px 10px',
    cursor: 'pointer'
};

const buttonGroupStyle = {
    display: 'flex',
    justifyContent: 'center'
};

const headingStyle = {
    textAlign: 'center'
};

export default ApproveRejectLocation;

















